import Nav from "../shared/nav";
import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useToken } from "../../auth/useToken";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

export default function Feed() {
  const navigate = useNavigate();

  const [token, setToken] = useToken();

  const [loading, setLoading] = useState(false);
  const [listings, setListings] = useState();
  const [hoteltypes, setHoteltypes] = useState();
  const [cities, setCities] = useState();
  const [offers, setOffers] = useState();

  const notifyerror = (msg) => toast.error(msg);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const logout = () => {
    setLoading(true);
    setToken("");
    navigate("/");
  };

  const recentlisting = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/recentlistings",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data;
        if (data === "") {
          notifyerror("Feed error!");
        } else {
        
          setListings(response.data.hotels);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const allcities = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/city/all",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data;
        if (data === "") {
          notifyerror("Feed error!");
        } else {
          setCities(response.data.cities);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const htypes = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/hotel/hoteltypes",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data;
        if (data === "") {
          notifyerror("Feed error!");
        } else {
          setHoteltypes(response.data.hoteltypes);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const getoffers = () => {
    setLoading(true);
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/offer/feedoffers",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.offers;
        if (data === "") {
          // console.log("Invalid offers!");
        } else {
          setOffers(data);
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        notifyerror("Connection error!");
      });
  };

  useEffect(() => {
    setLoading(true);
    recentlisting();
    htypes();
    allcities();
    getoffers();
  }, []);

  let htypesop = hoteltypes ? (
    hoteltypes.map((data, i) => {
      return <option value={data.id}>{data.type}</option>;
    })
  ) : (
    <span>No Data</span>
  );

  let citiesop = cities ? (
    cities.map((data, i) => {
      return <option value={data.id}>{data.name}</option>;
    })
  ) : (
    <span>No Data</span>
  );

  let hotels = listings ? (
    listings.map((data, i) => {
      return (
        <li className="relative border-b-2 mt-2 mb-2 py-2">
          <a href={`/listing/${data.id}`} className="font-bold text-[#636060]">
            {data.name}
            <br />
          </a>{" "}
          <span className="text-xs text-[#636060]">
            {data.stars} Star Hotel
          </span>
          <svg
            className="absolute top-3 right-2"
            xmlns="http://www.w3.org/2000/svg"
            width="9.982"
            height="19.999"
            viewBox="0 0 9.982 19.999"
          >
            <path
              id="arrow_icn"
              d="M1256.954,4439.382a1.113,1.113,0,0,0-1.681,1.458l7.119,8.16-7.119,8.16a1.112,1.112,0,0,0,1.681,1.457l7.754-8.888a1.109,1.109,0,0,0,0-1.458Z"
              transform="translate(-1255 -4439)"
              fill="#636060"
            />
          </svg>
        </li>
      );
    })
  ) : (
    <span>No Data</span>
  );

  let offerlist = offers ? (
    offers.map((data, i) => {
      return (
        <div className="w-full bg-white shadow-2xl rounded-[40px]">
          <div className="relative">
            <img
              src={process.env.REACT_APP_S3_URL + "/offers/" + data.img}
              alt=""
              className="h-[450px] object-cover rounded-t-[40px] w-full"
            />

            <span className="absolute top-0 right-0 p-2 rounded-l-2xl mt-10 text-white bg-[#00008B]">
              {data.title}
            </span>
          </div>

          <h3 className="text-xl mt-1 font-bold text-[#00008B] text-center">
            {data.name}
          </h3>

          <div className="flex justify-center">
            <div id="stars" className="inline-flex gap-2">
              {(() => {
                let starcount = parseInt(data.stars);

                let stars = [];

                for (let i = 0; i < starcount; i++) {
                  stars.push(<img src="/images/star.svg" className="h-6" />);
                }

                return stars;
              })()}


            </div>
          </div>

          <div className="w-full bg-[#EFEFEF] text-[#636060] mt-2 rounded inline-flex justify-between">
            <span className="font-bold ml-2">{data.city}</span>
            <button className="bg-[#00008B] text-white px-3 rounded-2xl inline-flex items-center mr-4">
              <a href={"tel:" + data.hotline} className="mr-5">
                Get Now
              </a>
              <span className="w-4 h-4">
                <img src="/images/logosecondary.svg" />
              </span>
            </button>
          </div>

          <p className="mb-4 ml-3 mt-1 text-center">{data.content}</p>

          <div className="justify-between rounded-[150px] p-2 columns-3 bg-[#EFEFEF]">
            <div className="w-full">
              <img className="mx-auto" src="/images/heart.svg" />
            </div>
            <div className="w-full">
              <img className="mx-auto" src="/images/comment.svg" />
            </div>
            <div className="w-full">
              <img className="mx-auto" src="/images/share.svg" />
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <span>No Data</span>
  );

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <div className="flex md:flex-row flex-col bg-[#F8F8F8]">

        <Nav />

        {/* <!-- main content --> */}

        <div className="md:flex-1 flex-col md:mr-1  mr-6">
          <div className="h-screen">
            <div className="p-10">
              {/* <!-- Search start--> */}
              <div className="relative flex flex-col md:flex-row">
                <input
                  type="text"
                  placeholder="Search"
                  className="focus:outline-none w-full p-3 border-2 border-[#00008B] rounded-3xl bg-white"
                />

                <div className="absolute p-1.5 right-0">
                  <img src="./images/bluefleeinn.svg" />
                </div>
              </div>

              <div className="flex flex-row columns-3 mt-4">
                <div className="w-full text-left mr-4">
                  <select
                    name=""
                    id=""
                    className="bg-blend-color-dodge w-full bg-white p-2 rounded-xl text-[#00008B] focus:outline-none"
                  >
                    <option value="">Select a Hotel Type</option>
                    {htypesop ? htypesop : <span>No results</span>}
                  </select>
                </div>
                <div className="w-full text-left mr-4">
                  <select
                    name=""
                    id=""
                    className="bg-blend-color-dodge w-full bg-white p-2 rounded-xl text-[#00008B] focus:outline-none"
                  >
                    <option value="">Select a City</option>
                    {citiesop ? citiesop : <span>No results</span>}
                  </select>
                </div>
                <div className="w-full">
                  <button className="text-center p-3 rounded-xl w-full text-white font-thin text-sm bg-[#00008b] hover:bg-[#050544] duration-300">
                    Advance Filters
                  </button>
                </div>
              </div>
            </div>
            {/* <!-- Search end--> */}

            <div className="h-96 px-6">
              <div className="flex md:flex-row flex-col colums-3 gap-2">
                {offerlist ? offerlist : <span>No Data</span>}
              </div>
            </div>
          </div>
        </div>

        <div className="md:block hidden flex flex-col w-96 mr-4">
          <div className="h-14 bg-white rounded-xl py-2 mt-10 w-full flex flex-row justify-between">
            <div className="w-full">
              <img className="mx-auto mt-2" src="/images/bookmarkblue.svg" />
            </div>
            <div className="w-full">
              <img className="mx-auto mt-2" src="/images/notifiblue.svg" />
            </div>
            <div className="w-full inline-flex">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="ml-5">
                    <div className="w-full inline-flex">
                      <img
                        src="/images/hilton.jpg"
                        className="rounded h-9 mr-2 mt-1"
                        alt=""
                        aria-hidden="true"
                      />
                      <ChevronDownIcon
                        className="-mr-1 mt-2 h-5 w-5 text-gray-800"
                        aria-hidden="true"
                      />
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                      <hr />
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={logout}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>

          {/* <!-- Recently added start --> */}

          <div className="bg-white rounded-2xl flex flex-col justify-center mt-5 border-2 border-[#00008B] ">
            <span className="font-bold text-center text-white bg-[#00008B] rounded-2xl py-2">
              Recently added listings
            </span>
            <ul className="ml-2 mt-2">
              {hotels ? hotels : <span>No results</span>}
            </ul>

            <a href="#" className="text-center p-1 text-[#636060]">
              More listings
            </a>
          </div>

          <div className="text-center p-20">Ad Space</div>
        </div>
      </div>
    </LoadingScreen>
  );
}
