import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Otpform from "./otpform";
import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";
import { useForm } from "react-hook-form";

function Register() {
  const [loading, setLoadig] = useState(false);
  const [show, setShow] = useState(true);

  const [userid, setUserid] = useState();

  const notifysuccess = () => toast.success("Sign up successful!");
  const notifyerror = (msg) => toast.error(msg);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();




  const onSubmit = (data) => {
    
    setLoadig(true);

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL+"/auth/register",
      data: data,
    };

    try {
      axios(config)
        .then((res) => {
          setUserid(res.data.userid)
          notifysuccess("Registraion successful!");
          setLoadig(false)
          setShow(false);
        })
        .catch((error) => {
       
          notifyerror(JSON.stringify(error.response.data.message));

          setLoadig(false)
         
        });
    } catch (error) {
      setLoadig(false)
      notifyerror("Server error!");
    }

    
  };

  return (
    
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>
        {/* <!-- Global Container --> */}
     
        <div className="flex items-center justify-center min-h-screen">
          <div className="z-0 absolute left-[200px] bottom-[-150px] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="496"
              height="496"
              viewBox="0 0 496 496"
            >
              <g
                id="Ellipse_20"
                data-name="Ellipse 20"
                fill="none"
                stroke="#00008b"
                stroke-width="71"
              >
                <circle cx="248" cy="248" r="248" stroke="none" />
                <circle cx="248" cy="248" r="212.5" fill="none" />
              </g>
            </svg>
          </div>

          <div className="z-0 absolute right-[200px] top-[-150px] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="496"
              height="496"
              viewBox="0 0 496 496"
            >
              <g
                id="Ellipse_20"
                data-name="Ellipse 20"
                fill="none"
                stroke="#00008b"
                stroke-width="71"
              >
                <circle cx="248" cy="248" r="248" stroke="none" />
                <circle cx="248" cy="248" r="212.5" fill="none" />
              </g>
            </svg>
          </div>
          {/* <!-- Card--> */}

          <div className="z-10 relative flex flex-col m-6 space-y-10 bg-white shadow-2xl rounded-[60px] md:flex-row md:space-y-0 md:m-0 ">
            {/* <!--Lef Side--> */}

            <div className="bg-[#00008b] md:h-[700px] md:w-[550px]  md:rounded-l-[60px] md:rounded-r-none  rounded-t-[60px] ">
              <h1 className="text-white text-center font-bold text-4xl md:mt-[275px] mt-[50px] ">
                Welcome to
              </h1>
              <img
                src="/images/logomain.svg"
                alt="fleeinn"
                className="mt-3 mx-auto w-[300px] mb-[50px]"
              />
            </div>

            {/* <!-- Right Side--> */}

            <div className=" md:w-[550px] mt-[50px] w-[400px]">
              <div className="text-left md:mt-20 md:pl-[100px] w-[400px] pl-[30px] pb-[30px]">
                {/* form section start  */}
                {show ? (
                  <div>
                    <h2 className=" font-bold text-3xl tracking-[0.03em] text-[#00008b] font-bold">
                      Get Started
                    </h2>
                    <p className="text-slate-400 pt-2 text-left">
                      Already have an account?{" "}
                      <a
                        href="/"
                        className="text-slate-500 underline font-bold"
                      >
                        {" "}
                        Sign in
                      </a>
                    </p>

                    <div className="text-left pt-4">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <input
                          {...register("fname", { required: true })}
                          type="text"
                          name="fname"
                          placeholder="First Name"
                          className="placeholder: font-bold bg-[#f5f6f8] focus:shadow-md outline-none p-2 placeholder-gray-400 w-[350px] "
                        />
                        {errors.fname && (
                          <span className="text-red-700 font-bold">
                            This field is required *{" "}
                          </span>
                        )}
                        <input
                          type="text"
                          name="lname"
                          {...register("lname", { required: true })}
                          placeholder="Last Name"
                          className="mt-6 placeholder: font-bold bg-[#f5f6f8] focus:shadow-md outline-none p-2 placeholder-gray-400 w-[350px] "
                        />
                        {errors.lname && (
                          <span className="text-red-700 font-bold">
                            This field is required *{" "}
                          </span>
                        )}
                        <input
                          {...register("email", { required: true })}
                          type="email"
                          name="email"
                          placeholder="Your email"
                          className="mt-6 placeholder:font-thin font-bold bg-[#f5f6f8] focus:shadow-md outline-none p-2 placeholder-gray-400 w-[350px] "
                        />
                        {errors.email && (
                          <span className="text-red-700 font-bold">
                            This field is required *{" "}
                            <br />
                          </span>
                        )}
                        <label className="mt-6 text-gray-400  mr-2 ">
                          Gender :
                        </label>
                        <input
                        
                        {...register("gender", { required: true })}
                          type="radio"
                          name="gender"
                          value="1"
                          className="accent-[#00008b]"
                        />{" "}
                        <label className="mt-6 text-gray-400 font-thin mr-2 ">
                          Male
                        </label>
                        <input
                          {...register("gender", { required: true })}
                          type="radio"
                          name="gender"
                          value="0"
                          className="mt-6 accent-[#00008b]"
                        />{" "}
                        <label className="mt-6 text-gray-400 font-thin mr-2 ">
                          Female
                        </label>
                        {errors.gender && (
                         
                          <span className="text-red-700 font-bold">
                             <br />
                            This field is required *{" "}
                            
                          </span>
                        )}
                        <br />
                        <label className="mt-8 text-gray-400 mr-2 ">
                          Date of Birth :
                        </label>
                        <input
                          {...register("dob", { required: true })}
                          name="dob"
                          type="date"
                          placeholder="Your birthday"
                          className=" mt-3 placeholder:font-thin font-bold bg-[#f5f6f8] focus:shadow-md outline-none p-2 placeholder-gray-400 w-[350px] "
                        />
                        {errors.dob && (
                          <span className="text-red-700 font-bold">
                            This field is required *{" "}
                          </span>
                        )}
                        <div className=" mt-5"></div>
                        <input
                          {...register("mobile", { required: true })}
                          id="phone"
                          type="text"
                          name="mobile"
                          placeholder="Your phone number"
                          className=" placeholder: font-bold bg-[#f5f6f8] focus:shadow-md outline-none p-2 placeholder-gray-400 w-[350px] "
                        />
                        {errors.phone && (
                          <span className="text-red-700 font-bold">
                            This field is required *{" "}
                          </span>
                        )}
                        <input
                          {...register("password", { required: true })}
                          type="password"
                          name="password"
                          placeholder="Password"
                          className="mt-6 placeholder: font-bold bg-[#f5f6f8] focus:shadow-md outline-none p-2 placeholder-gray-400 w-[350px] "
                        />
                        {errors.password && (
                          <span className="text-red-700 font-bold">
                            This field is required *{" "}
                          </span>
                        )}
                        <button
                          type="submit"
                          className="text-center p-3 rounded-xl mt-3 w-[350px] text-white font-thin text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
                        >
                          Sign Up
                        </button>
                      </form>
                      <p className="text-slate-400 text-xs pt-2 text-left">
                        By clicking Sign Up, you agree to our{" "}
                        <a href="#" className="text-slate-500 font-bold">
                          {" "}
                          Terms
                        </a>
                        ,{" "}
                        <a href="#" className="text-slate-500 font-bold">
                          {" "}
                          Privacy Policy
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                ) : (
                  <Otpform userid={userid} />
                )}

                {/* form section end  */}
              </div>
            </div>

            <div className="absolute md:left-[450px] md:top-[450px] md:block hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
                viewBox="0 0 337 337"
              >
                <g
                  id="Ellipse_21"
                  data-name="Ellipse 21"
                  fill="none"
                  stroke="#fff"
                  stroke-width="81"
                >
                  <circle cx="168.5" cy="168.5" r="168.5" stroke="none" />
                  <circle cx="168.5" cy="168.5" r="128" fill="none" />
                </g>
              </svg>
            </div>

            <div className="absolute left-[-70px] top-[-40px] hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="175"
                viewBox="0 0 337 337"
              >
                <g
                  id="Ellipse_21"
                  data-name="Ellipse 21"
                  fill="none"
                  stroke="#fff"
                  stroke-width="81"
                >
                  <circle cx="168.5" cy="168.5" r="168.5" stroke="none" />
                  <circle cx="168.5" cy="168.5" r="128" fill="none" />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </body>
    </LoadingScreen>
  );
}

export default Register;
