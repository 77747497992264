import { useState } from "react";
import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";
import PropertyNav from "./newproperty/nav";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useToken } from "../../auth/useToken";

export default function GalleryUpload() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress

  const [token] = useToken();

  // Set up the dropzone
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setImages((prevImages) => [...prevImages, ...acceptedFiles]);
      const newPreviews = acceptedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
    },
  });

  // Function to remove an image
  const handleDelete = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, i) => i !== index)
    );
  };

  // Function to upload images
  const handleUpload = async () => {
    if (images.length === 0) return;

    // Reset progress and start loading
    setUploadProgress(0);
    // setLoading(true);

    const formData = new FormData();
    images.forEach((image) => formData.append("images[]", image)); // Append all images
    formData.append("hotel_id", id); // Send room_id as well

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/hotel/image",
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            // Calculate the progress (but keep it below 100%)
            const percentCompleted = Math.round(
              (progressEvent.loaded * 99) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      // If the upload is successful and response is 201, set progress to 100%
      if (response.status === 201) {
        setUploadProgress(100);
        console.log("Upload successful", response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error uploading images", error);
      setLoading(false);
    }
  };

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <div>
        <PropertyNav />
        <div className="bg-gray-500 h-screen w-screen sm:px-8 md:px-16 sm:py-8">
          <main className="container mx-auto max-w-screen-lg h-full">
            <article className="relative h-full flex flex-col bg-white shadow-xl rounded-md ">
              <section className="h-full overflow-auto p-8 w-full h-full flex flex-col">
                <header
                  {...getRootProps({
                    className:
                      "border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center",
                  })}
                >
                  <input {...getInputProps()} multiple />
                  <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
                    <span>Drag and drop your files anywhere or</span>
                  </p>
                  <button className="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none">
                    Upload a file
                  </button>
                </header>

                <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                  Selected Files
                </h1>

                <ul className="flex flex-1 flex-wrap -m-1">
                  {imagePreviews.length === 0 && (
                    <li className="h-full w-full text-center flex flex-col items-center justify-center">
                      <img
                        className="mx-auto w-32"
                        src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                        alt="no data"
                      />
                      <span className="text-small text-gray-500">
                        No files selected
                      </span>
                    </li>
                  )}

                  {imagePreviews.map((preview, index) => (
                    <li
                      key={index}
                      className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24"
                    >
                      <article className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline relative bg-gray-100 cursor-pointer shadow-sm">
                        <img
                          src={preview}
                          alt={`Preview ${index}`}
                          className="img-preview w-full h-full sticky object-cover rounded-md"
                        />
                        <button
                          className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full"
                          onClick={() => handleDelete(index)}
                        >
                          Delete
                        </button>
                      </article>
                    </li>
                  ))}
                </ul>
                {uploadProgress > 0 && (
                <div className="w-full bg-gray-200 rounded-full mt-4">
                  <div
                    className={`text-xs font-medium text-center p-0.5 leading-none ${
                      uploadProgress === 100
                        ? "bg-green-600 text-green-100"
                        : "bg-blue-600 text-blue-100"
                    }`}
                    style={{ width: `${uploadProgress}%` }}
                  >
                    {uploadProgress}%
                  </div>
                </div>
              )}
              </section>

      

              <footer className="flex justify-end px-8 pb-8 pt-4">
                <button
                  className="rounded-sm px-3 py-1 bg-blue-700 hover:bg-blue-500 text-white focus:shadow-outline focus:outline-none"
                  onClick={handleUpload}
                >
                  Upload now
                </button>
              </footer>
            </article>
          </main>
        </div>
      </div>
    </LoadingScreen>
  );
}
