import axios from "axios";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";

function Otpform(props) {
  const navigate = useNavigate();

  const [loading, setLoadig] = useState(false);
  const notifysuccess = (msg) => toast.success(msg);
  const notifyerror = (msg) => toast.error(msg);

  const [otpref, setotpref] = useState();


  const verifyCode = async (e) => {
    e.preventDefault();

    setLoadig(true);

    var data = {
      code: e.target.otp.value,
      user_id: props.userid,
    };

    var config = {
      method: "post",
      url:
        process.env.REACT_APP_API_URL +"/auth/verify",
      data: data,
    };

    axios(config)
      .then(function (response) {
 
        if (response.data.success === false) {
          setLoadig(false);
          notifyerror("Incorrect verification code!");
         
        } else if (response.data.success === true) {
          setLoadig(false);
          notifysuccess("Account verified! Please login");
          // navigate(0);
        }
      })
      .catch(function (error) {
        setLoadig(false);
        notifyerror(error.response.data.message);
      });
  };

  useEffect(() => {
   
  }, []);

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <div>
        <h2 class=" font-bold text-3xl tracking-[0.03em] text-[#00008b] font-bold">
          Verify your account
        </h2>
        <p class="text-slate-400 pt-2 text-left">
          Enter the Verification code you have received to your mobile
        </p>

        <div class="text-left pt-4">
          <form onSubmit={verifyCode}>
            <input
              type="number"
              required
              name="otp"
              placeholder="Verification Code"
              class="placeholder:font-thin font-bold bg-[#f5f6f8] focus:shadow-md outline-none p-2 placeholder-gray-400 w-[350px]"
            />

            <button
              type="submit"
              class="text-center p-3 rounded-xl mt-3 w-[350px] text-white font-thin text-sm bg-[#00008b] hover:bg-[#050544] duration-300"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </LoadingScreen>
  );
}

export default Otpform;
