import { Route, Routes } from "react-router";
import Login from "./components/user/login";
import Signup from "./components/user/register";

import Feed from "./components/feed/feed";

import Profilelisting from "./components/listing/profilelisting";
import MyListings from "./components/listing/mylistings";
import Managelisting from "./components/listing/managelisting";
import Listings from "./components/listing/listings";

// Add property steps
import PropertyName from "./components/listing/newproperty/name";
import PropertyType from "./components/listing/newproperty/type";
import PropertyAddress from "./components/listing/newproperty/address";
import PropertyMap from "./components/listing/newproperty/map";
import PropertySurroundings from "./components/listing/newproperty/surroundings";
import PropertyAmenities from "./components/listing/newproperty/amenities";
import PropertyContact from "./components/listing/newproperty/contact";
import PropertyRules from "./components/listing/newproperty/rules";
import PropertyThankyou from "./components/listing/newproperty/thankyou";

import ManageGallery from "./components/listing/managegallery";
import GalleryUpload from "./components/listing/galleryupload";

//Rooms
import ManageRoom from "./components/rooms/manage";
import AddRoom from "./components/rooms/add";
import RoomImages from "./components/rooms/images";
import ManageRoomImages from "./components/rooms/manageimages";

//Offers
import ManageOffers from "./components/offers/manage";

import { PrivateRoute } from "./auth/PrivateRoute";

import Example from "./components/example";
import NotFound from "./components/404";


export const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/ex" element={<Example />} />

      <Route path="/feed" element={<PrivateRoute />}>
        <Route path="/feed" element={<Feed />} />
      </Route>

      <Route path="/listing/:id" element={<PrivateRoute />}>
        <Route path="/listing/:id" element={<Profilelisting />} />
      </Route>

      <Route path="/mylistings" element={<PrivateRoute />}>
        <Route path="/mylistings" element={<MyListings />} />
      </Route> 
      
       <Route path="/managelisting/:id/:name" element={<PrivateRoute />}>
        <Route path="/managelisting/:id/:name" element={<Managelisting />} />
      </Route>

      <Route path="/listings" element={<PrivateRoute />}>
        <Route path="/listings" element={<Listings />} />
      </Route>

      {/* Add property steps */}
      <Route
        path="/addlisting/updatepropertyname/:id"
        element={<PrivateRoute />}
      >
        <Route
          path="/addlisting/updatepropertyname/:id"
          element={<PropertyName />}
        />
      </Route>

      <Route path="/addlisting/propertyname" element={<PrivateRoute />}>
        <Route path="/addlisting/propertyname" element={<PropertyName />} />
      </Route>

      <Route path="/addlisting/propertytype/:id" element={<PrivateRoute />}>
        <Route path="/addlisting/propertytype/:id" element={<PropertyType />} />
      </Route>

      <Route path="/addlisting/address/:id" element={<PrivateRoute />}>
        <Route path="/addlisting/address/:id" element={<PropertyAddress />} />
      </Route>

      <Route path="/addlisting/map/:id" element={<PrivateRoute />}>
        <Route path="/addlisting/map/:id" element={<PropertyMap />} />
      </Route>

      <Route path="/addlisting/surroundings/:id" element={<PrivateRoute />}>
        <Route
          path="/addlisting/surroundings/:id"
          element={<PropertySurroundings />}
        />
      </Route>

      <Route path="/addlisting/amenities/:id" element={<PrivateRoute />}>
        <Route
          path="/addlisting/amenities/:id"
          element={<PropertyAmenities />}
        />
      </Route>

      <Route path="/addlisting/contact/:id" element={<PrivateRoute />}>
        <Route path="/addlisting/contact/:id" element={<PropertyContact />} />
      </Route>

      <Route path="/addlisting/rules/:id" element={<PrivateRoute />}>
        <Route path="/addlisting/rules/:id" element={<PropertyRules />} />
      </Route>

      <Route path="/addlisting/thankyou" element={<PrivateRoute />}>
        <Route path="/addlisting/thankyou" element={<PropertyThankyou />} />
      </Route>

      <Route path="/listing/images/:id" element={<PrivateRoute />}>
        <Route path="/listing/images/:id" element={<ManageGallery />} />
      </Route>

      <Route path="/listing/galleryupload/:id" element={<PrivateRoute />}>
        <Route path="/listing/galleryupload/:id" element={<GalleryUpload />} />
      </Route>


      {/* rooms start */}

      <Route path="/rooms/manage/:id" element={<PrivateRoute />}>
        <Route path="/rooms/manage/:id" element={<ManageRoom />} />
      </Route>

      <Route path="/rooms/add/:id" element={<PrivateRoute />}>
        <Route path="/rooms/add/:id" element={<AddRoom />} />
      </Route>   
      
      
       <Route path="/rooms/images/add/:id" element={<PrivateRoute />}>
        <Route path="/rooms/images/add/:id" element={<RoomImages />} />
      </Route>     
      
      <Route path="/rooms/images/:id" element={<PrivateRoute />}>
        <Route path="/rooms/images/:id" element={<ManageRoomImages />} />
      </Route>

      {/* Offers start */}
      <Route path="/offers/manage/:id/:name" element={<PrivateRoute />}>
        <Route path="/offers/manage/:id/:name" element={<ManageOffers />} />
      </Route>
    </Routes>
  );
};

export default MainRoutes;
