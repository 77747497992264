import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropertyNav from "../listing/newproperty/nav";
import axios from "axios";
import { toast } from "react-toastify";
import { useToken } from "../../auth/useToken";

export default function ManageRoomImages() {
  const { id } = useParams();
  const [token] = useToken();
  const [images, setImages] = useState();
  const [loading, setLoading] = useState(false);

  const notifysuccess = (msg) => toast.success(msg);
  const notifyerror = (msg) => toast.error(msg);

  useEffect(() => {
    getImages();
  }, []);

  const getImages = () => {
    setLoading(true);
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/room/images/" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const data = response.data.images;

        setImages(data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        notifyerror("Connection error!");
      });
  };

  const deleteimage = (imgid) => {
    setLoading(true);
    var config = {
      method: "delete",
      url: process.env.REACT_APP_API_URL + "/room/image/" + imgid,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        notifysuccess("Image Deleted!");

        getImages();
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let imagelist = images ? (
    images.map((data, i) => {
      return (
        <div class="m-5 rounded overflow-hidden shadow-lg ">
          <img
            class="w-full"
            src={process.env.REACT_APP_S3_URL + "/rooms/" + data.image}
          />

          <div class="px-6 pt-4 pb-2">
            <button
              onClick={() => deleteimage(data.id)}
              class="inline-block bg-red-600 rounded px-3 py-1 font-semibold text-white mr-2 mb-2 hover:bg-red-400 hover:text-black"
            >
              Delete
            </button>
          </div>
        </div>
      );
    })
  ) : (
    <span>No Data</span>
  );

  return (
    <LoadingScreen loading={loading} bgColor="#ffffffcc" logoSrc={loader}>
      <body>
        <PropertyNav />

        <div className="mt-5 items-center">
          <a
            href={"/rooms/images/add/" + id}
            class=" w-md rounded px-3 py-1 font-semibold text-white mr-2 mb-2 bg-[#00008B] hover:bg-blue-900 hover:text-white"
          >
            Add Images
          </a>
        </div>

        <div className="grid grid-cols-3">
          {imagelist ? imagelist : <span>No Data</span>}
        </div>
      </body>
    </LoadingScreen>
  );
}
